<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Member
          <v-spacer></v-spacer>

          <router-link
            to="/nakes-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah </v-btn>
          </router-link>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedPropinsi"
              label="Propinsi"
              clearable
              dense
              outlined
              :items="items_propinsi"
              item-text="name"
              @change="changePropinsi"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedKabupaten"
              label="Kota/Kabupaten"
              clearable
              dense
              outlined
              :items="items_kabupaten"
              item-text="name"
              @change="changeKabupaten"
              @click="getListKabupaten"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedProfesi"
              label="Profesi"
              clearable
              dense
              outlined
              :items="items_profesi"
              item-text="value"
              @change="changeProfesi"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="instansi"
              label="Cari Instansi"
              single-line
              outlined
              dense
              hide-details
              readonly
              clearable
              @click:clear="clearInstansi"
              @change="getDataFromApi"
              @click="openInstansiModal"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <InstansiSelectComponent
        ref="modalInstansi"
        v-model="dialogInstansi"
        @pilihInstansi="pilihInstansi"
      />
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import InstansiSelectComponent from "../components/InstansiSelect.vue";
// import KomisariatService from "../services/KomisariatService";
// import store from "../store/index";
// import DPWService from "../services/DPWService";
// import DPDService from "../services/DPDService";

export default {
  components: {
    InstansiSelectComponent,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Member",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    dialogInstansi: false,
    search: "",
    instansi: "",
    instansiId: "",
    selectedDpw: "",
    selectedDpd: "",
    selectedKomisariat: "",
    selectedPropinsi: "",
    selectedKabupaten: "",
    selectedProfesi: "",
    // items_komisariat: [],
    // items_dpw: [],
    items_propinsi: [],
    items_kabupaten: [],
    items_profesi: [],
    // items_dpd: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "NIK", value: "no_ktp", sortable: false },
      { text: "Propinsi", value: "propinsi_name", sortable: false },
      { text: "Kota/Kabupaten", value: "kabupaten_name", sortable: false },
      { text: "Profesi", value: "profesi_name", sortable: false },
      // { text: "STR", value: "str", sortable: false },
      // { text: "DPW", value: "dpw_nama", sortable: false },
      // { text: "DPD", value: "dpd_nama", sortable: false },
      // { text: "Komisariat", value: "komisariat_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
  }),
  computed: {
    // getKomisariatId() {
    //   return store.getters.getUser.komisariat_id;
    // },
    // isAdminKomisariat() {
    //   if (store.getters.getUser.komisariat_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPropinsi();
    this.getListProfesi();
    this.getDataFromApi();
  },
  methods: {
    openInstansiModal: async function () {
      this.$refs.modalInstansi.getDataFromApi();
      this.dialogInstansi = true;
    },

    clearInstansi: async function () {
      this.instansi = "";
      this.instansiId = "";

      this.getDataFromApi();
    },

    pilihInstansi(item) {
      this.dialogInstansi = false;
      this.instansi = item.nama;
      this.instansiId = item.id;

      this.getDataFromApi();
    },
    changePropinsi() {
      this.selectedKabupaten = null;
      this.getDataFromApi();
    },

    changeKabupaten() {
      this.getDataFromApi();
    },

    changeProfesi() {
      this.getDataFromApi();
    },

    // changeDpw() {
    //   this.selectedDpd = null;
    //   this.selectedKomisariat = null;
    //   this.getDataFromApi();
    // },

    // changeDpd() {
    //   this.selectedKomisariat = null;
    //   this.getDataFromApi();
    // },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListProfesi() {
      NakesService.profesiList()
        .then((res) => {
          this.items_profesi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // async getListDpd() {
    //   DPDService.dpdListAll(this.selectedDpw.id)
    //     .then((res) => {
    //       this.items_dpd = res.data.list_data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // async getListKomisariat() {
    //   KomisariatService.komisariatListAllFilter(this.selectedDpd.id)
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var komisariat = "";

      if (this.selectedKomisariat) {
        komisariat = this.selectedKomisariat.id;
      }

      var dpw = "";
      var dpd = "";
      var propinsi = "";
      var kabupaten = "";
      var profesi = "";

      if (this.selectedDpw) {
        dpw = this.selectedDpw.id;
      }

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      if (this.selectedPropinsi) {
        propinsi = this.selectedPropinsi.id;
      }

      if (this.selectedKabupaten) {
        kabupaten = this.selectedKabupaten.id;
      }

      if (this.selectedProfesi) {
        profesi = this.selectedProfesi.code;
      }

      this.loading = true;
      NakesService.nakesList(
        page,
        itemsPerPage,
        this.search,
        komisariat,
        dpw,
        dpd,
        propinsi,
        kabupaten,
        profesi,
        this.instansiId,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    // changeKomisariat() {
    //   this.getDataFromApi();
    // },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/nakes-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      NakesService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus Member berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus Member gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
