<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" />

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Instansi
          <v-spacer />

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Instansi</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.nama"
                        label="Nama"
                        required
                        :rules="currentData.namaRules"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.alamat"
                        label="Alamat"
                        required
                        :rules="currentData.alamatRules"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedPropinsi"
                        label="Propinsi"
                        clearable
                        dense
                        outlined
                        :items="items_propinsi"
                        item-text="name"
                        @change="changePropinsi"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedKabupaten"
                        label="Kabupaten/Kota"
                        clearable
                        dense
                        outlined
                        :items="items_kabupaten"
                        item-text="name"
                        @click="getListKabupaten"
                        @change="changeKabupaten"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedKecamatan"
                        label="Kecamatan"
                        clearable
                        dense
                        outlined
                        :items="items_kecamatan"
                        item-text="name"
                        @click="getListKecamatan"
                        @change="changeKecamatan"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedKelurahan"
                        label="Kelurahan"
                        clearable
                        dense
                        outlined
                        :items="items_kelurahan"
                        item-text="name"
                        @click="getListKelurahan"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Apa anda akan menghapus data ini?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Tidak
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  Ya
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search.nama"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="search.selectedPropinsi"
              label="Propinsi"
              clearable
              dense
              outlined
              :items="items_propinsi"
              item-text="name"
              @change="changePropinsiSearch"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="search.selectedKabupaten"
              label="Kota/Kabupaten"
              clearable
              dense
              outlined
              :items="items_search_kabupaten"
              item-text="name"
              @change="changeKabupatenSearch"
              @click="getListKabupatenSearch"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import InstansiService from "../services/InstansiService";
import CommonService from "../services/CommonService";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Instansi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      nama: "",
      alamat: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      alamatRules: [(v) => !!v || "Alamat harus diisi"],
    },

    search: {
      nama: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
    },

    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],
    items_search_kabupaten: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPropinsi();
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var propinsi = "";
      var kabupaten = "";

      if (this.search.selectedPropinsi) {
        propinsi = this.search.selectedPropinsi.id;
      }

      if (this.search.selectedKabupaten) {
        kabupaten = this.search.selectedKabupaten.id;
      }

      this.loading = true;
      InstansiService.instansiList(
        page,
        itemsPerPage,
        this.search.nama,
        propinsi,
        kabupaten,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsiSearch() {
      this.search.selectedKabupaten = null;

      this.getDataFromApi();
    },

    async changeKabupatenSearch() {
      this.getDataFromApi();
    },

    async changePropinsi() {
      this.currentData.selectedKabupaten = null;
      this.currentData.selectedKecamatan = null;
      this.currentData.selectedKelurahan = null;
    },

    async changeKabupaten() {
      this.currentData.selectedKecamatan = null;
      this.currentData.selectedKelurahan = null;
    },

    async changeKecamatan() {
      this.currentData.selectedKelurahan = null;
    },

    async getListKabupatenSearch() {
      NakesService.kabupatenList(this.search.selectedPropinsi.id)
        .then((res) => {
          this.items_search_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.currentData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatan() {
      NakesService.kecamatanList(this.currentData.selectedKabupaten.id)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahan() {
      NakesService.kelurahanList(this.currentData.selectedKecamatan.id)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.nama = "";
      this.currentData.alamat = "";
      this.currentData.selectedPropinsi = "";
      this.currentData.selectedKabupaten = "";
      this.currentData.selectedKecamatan = "";
      this.currentData.selectedKelurahan = "";
      this.currentData.id = null;
      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id == null) {
        InstansiService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Instansi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah Instansi gagal. Coba lagi");
          });
      } else {
        InstansiService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update Instansi berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update Instansi gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.alamat = this.editedItem.alamat;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.id = this.editedItem.id;

      if (this.editedItem.propinsi_id) {
        this.items_propinsi.forEach((entry) => {
          if (entry.id == this.editedItem.propinsi_id) {
            this.currentData.selectedPropinsi = entry;
          }
        });

        this.getListKabupatenAll(
          this.editedItem.propinsi_id,
          this.editedItem.kabupaten_id,
        );

        this.getListKecamatanAll(
          this.editedItem.kabupaten_id,
          this.editedItem.kecamatan_id,
        );

        this.getListKelurahanAll(
          this.editedItem.kecamatan_id,
          this.editedItem.kelurahan_id,
        );
      }

      console.log("do add");
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.currentData.selectedKabupaten = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatanAll(kabupatenId, kecamatanId) {
      NakesService.kecamatanList(kabupatenId)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
          if (kecamatanId) {
            this.items_kecamatan.forEach((entry) => {
              if (entry.id == kecamatanId) {
                this.currentData.selectedKecamatan = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahanAll(kecamatanId, kelurahanId) {
      NakesService.kelurahanList(kecamatanId)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
          if (kelurahanId) {
            this.items_kelurahan.forEach((entry) => {
              if (entry.id == kelurahanId) {
                this.currentData.selectedKelurahan = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      InstansiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Instansi berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus Instansi gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
